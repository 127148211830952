import routerOptions0 from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}