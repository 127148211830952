import { default as _91_46_46_46cms_93lr6TIXM8OpMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93Ah3bi0Z1u4Meta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/blog/[...slug].vue?macro=true";
import { default as indexi6qfHl4B9YMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93Bh48p5DgI7Meta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removeolEJEmw5GqMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93FnxFxtAYecMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/product/[...slug].vue?macro=true";
import { default as index50P9qfoTwCMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93NKtpMEtxFuMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/realty/[...slug].vue?macro=true";
import { default as indexIRIuotrWylMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/sitemap/index.vue?macro=true";
import { default as index2Tzm9IX3DoMeta } from "/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93lr6TIXM8OpMeta?.name ?? "cms",
    path: _91_46_46_46cms_93lr6TIXM8OpMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93lr6TIXM8OpMeta || {},
    alias: _91_46_46_46cms_93lr6TIXM8OpMeta?.alias || [],
    redirect: _91_46_46_46cms_93lr6TIXM8OpMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Ah3bi0Z1u4Meta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93Ah3bi0Z1u4Meta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93Ah3bi0Z1u4Meta || {},
    alias: _91_46_46_46slug_93Ah3bi0Z1u4Meta?.alias || [],
    redirect: _91_46_46_46slug_93Ah3bi0Z1u4Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexi6qfHl4B9YMeta?.name ?? "blog",
    path: indexi6qfHl4B9YMeta?.path ?? "/blog",
    meta: indexi6qfHl4B9YMeta || {},
    alias: indexi6qfHl4B9YMeta?.alias || [],
    redirect: indexi6qfHl4B9YMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Bh48p5DgI7Meta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93Bh48p5DgI7Meta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93Bh48p5DgI7Meta || {},
    alias: _91_46_46_46slug_93Bh48p5DgI7Meta?.alias || [],
    redirect: _91_46_46_46slug_93Bh48p5DgI7Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removeolEJEmw5GqMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removeolEJEmw5GqMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removeolEJEmw5GqMeta || {},
    alias: temp_catalog_need_32_removeolEJEmw5GqMeta?.alias || [],
    redirect: temp_catalog_need_32_removeolEJEmw5GqMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93FnxFxtAYecMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93FnxFxtAYecMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93FnxFxtAYecMeta || {},
    alias: _91_46_46_46slug_93FnxFxtAYecMeta?.alias || [],
    redirect: _91_46_46_46slug_93FnxFxtAYecMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index50P9qfoTwCMeta?.name ?? "product",
    path: index50P9qfoTwCMeta?.path ?? "/product",
    meta: index50P9qfoTwCMeta || {},
    alias: index50P9qfoTwCMeta?.alias || [],
    redirect: index50P9qfoTwCMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93NKtpMEtxFuMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93NKtpMEtxFuMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93NKtpMEtxFuMeta || {},
    alias: _91_46_46_46slug_93NKtpMEtxFuMeta?.alias || [],
    redirect: _91_46_46_46slug_93NKtpMEtxFuMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexIRIuotrWylMeta?.name ?? "sitemap",
    path: indexIRIuotrWylMeta?.path ?? "/sitemap",
    meta: indexIRIuotrWylMeta || {},
    alias: indexIRIuotrWylMeta?.alias || [],
    redirect: indexIRIuotrWylMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: index2Tzm9IX3DoMeta?.name ?? "wish",
    path: index2Tzm9IX3DoMeta?.path ?? "/wish",
    meta: index2Tzm9IX3DoMeta || {},
    alias: index2Tzm9IX3DoMeta?.alias || [],
    redirect: index2Tzm9IX3DoMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/tmn/astondom-front-20241011093108/pages/wish/index.vue").then(m => m.default || m)
  }
]